
import loadable from '@loadable/component';
import "@fontsource/roboto-mono";
import {
  BrowserRouter as Router,
} from "react-router-dom";


import { HelmetProvider } from 'react-helmet-async';
import {setInit} from './init';

import './App.css';

const Theme = loadable(() => import('./Theme'));
const Routes = loadable(() => import('./Routes'));
const Layout = loadable(() => import('./layout/layout'));


const  App = () =>  {
 const locale="es";
 setInit();

  return (
    <Theme>
      <HelmetProvider>
          <Router>
            <Layout locale={locale} >
              <Routes locale={locale} />
            </Layout>
          </Router>
      </HelmetProvider>
    </Theme>
  );
}

export default App;
